/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import "reset-css"
import "../assets/fonts/graphik/font.css"
import "bootstrap/dist/css/bootstrap-grid.css"
import "./layout.css"

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import Header from "./header"


const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title}/>
      <div className="container">
        <main>{children}</main>
      </div>
      <Footer>
        <div className="container ">

          <a href="https://www.iubenda.com/privacy-policy/41611314"
             className="iubenda-white iubenda-embed"
             title="Privacy Policy ">Privacy policy</a>
          <br />
          <br />
          © {new Date().getFullYear()}, Startup Station.
          &nbsp;&nbsp;&nbsp;&nbsp;
          <script dangerouslySetInnerHTML={{
            __html: `
              (function (w, d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})
              (window, document
              );
            `,
          }}/>
        </div>
      </Footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const Footer = styled.footer`
    
    bottom: 0;
    width: 100%;
    padding: 30px 0 50px;
    color: #686868;
    font-size: 0.9em;
    @media (min-width: 778px) {
        position: fixed;
    }
    
    a {
      color: #777;
      &:hover {
        text-decoration: underline;
      }
    }
`

export default Layout
