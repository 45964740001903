import PropTypes from "prop-types"
import React from "react"
import styled from '@emotion/styled'

const assets = {
    logo: require('../assets/images/logo.svg')
}

const Header = ({siteTitle}) => (
    <StyledHeader>
        <div className="container">
            <img src={assets.logo} width={180} height="auto" alt={siteTitle}/>
        </div>
    </StyledHeader>
)

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

const StyledHeader = styled.header`
    padding: 60px 0;
`

export default Header
